import './styles/fonts.scss';

import './styles/general.scss';
import './styles/header.scss';
import './styles/footer.scss';
import './styles/home.scss';
import './styles/about.scss';
import './styles/service.scss';
import './styles/contact.scss';
import './styles/parts.scss';
import './styles/search.scss';
import './styles/vehicles.scss';
import './styles/gallery.scss';
import './styles/lightbox.scss';
import './styles/details.scss';
import './styles/search-results.scss';
import './styles/checkout.scss';
import './styles/custom-select.scss';
import './styles/modal.scss';


import './styles/main.scss';
